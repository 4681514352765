import React from "react";
import Title from "./components/title";
import Text from "./components/text";
import { StyledA } from "./components/link";
import {
	faMastodon,
	faTumblr,
	faGithub,
	faDiscord,
} from "@fortawesome/fontawesome-free-brands";
import {
	faCommentDots,
	faEnvelope,
	faKey,
} from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const Table = styled.table`
	color: rgb(20, 15, 25);
	@media (prefers-color-scheme: dark) {
		color: white;
	}

	& > tr {
		border: 0;
	}

	td {
		padding: 0.25em 0.5em 0.25em 0.5em;
		border: 0;
		vertical-align: top;
	}

	@media (max-width: 500px) {
		td:first-child {
			display: none;
		}
	}
`;

const Deemphasized = styled.span`
	color: rgb(20, 15, 25);
	opacity: 0.7;
	@media (prefers-color-scheme: dark) {
		color: white;
		opacity: 0.5;
	}
	font-weight: 400;
	text-decoration: none;
`;

const sources = [
	{
		icon: faMastodon,
		name: "Mastodon",
		url: "https://indieweb.social/@stevestreza",
		render: () => (
			<span>
				<Deemphasized>indieweb.social/</Deemphasized>
				<strong>@stevestreza</strong>
			</span>
		),
	},
	{
		icon: faGithub,
		name: "GitHub",
		url: "https://github.com/SteveStreza",
		render: () => ["SteveStreza"],
	},
	{
		icon: faTumblr,
		name: "Tumblr",
		url: "https://stevestreza.tumblr.com/",
		render: () => ["stevestreza"],
	},
	{
		icon: faDiscord,
		name: "Discord",
		render: () => (
			<span>
				<strong>Steeb</strong>
				<Deemphasized>#0365</Deemphasized>
			</span>
		),
	},
	{
		name: "Libera IRC",
		icon: faCommentDots,
		render: () => ["stevestreza"],
	},
	{
		name: "Email",
		icon: faEnvelope,
		render: () => (
			<span>
				<strong>first name</strong> <Deemphasized>at</Deemphasized>{" "}
				<strong>this domain name</strong>
			</span>
		),
	},
	{
		name: "GPG Key",
		icon: faKey,
		url: "/stevestreza.pub",
		render: () => [
			"DFED 4E42 34E7 348C 57D4",
			<br />,
			"6568 C4DC 30F8 5ABC 6FA1",
		],
	},
];

const Name = styled.td`
	text-align: right;
`;
const IconContainer = styled.td`
	text-align: center;
`;

const Links = () => (
	<Text>
		<Title>Find Me Online</Title>
		<Table border={0}>
			{sources.map(({ icon, name, url, render }) => (
				<tr border={0}>
					<Name>{name}</Name>
					<IconContainer>
						<FontAwesomeIcon icon={icon} />
					</IconContainer>
					<td>
						{url && <StyledA href={url}>{render()}</StyledA>}
						{!url && render()}
					</td>
				</tr>
			))}
		</Table>
	</Text>
);
export default Links;
