import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import styled from "styled-components";

const LargePhoto = styled(Img)`
	height: 120px;
	width: 120px;
	border-radius: 60px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.7);
	display: inline-block;
	text-align: center;
	@media only screen and (max-width: 450px) {
		margin-bottom: 1em;
	}
`;

const LargeMe = (props) => (
	<StaticQuery
		query={graphql`
			query LargeIcon {
				photo: file(relativePath: { eq: "photo.jpg" }) {
					full: childImageSharp {
						gatsbyImageData(layout: FIXED)
					}
					large: childImageSharp {
						gatsbyImageData(layout: FIXED, width: 120, height: 120)
					}
				}
			}
		`}
		render={(data) => [
			<Img
				key="u-photo"
				style={{ display: "none" }}
				className="u-photo"
				image={data.photo.full.gatsbyImageData}
			/>,
			<LargePhoto
				key="gatsby-photo"
				{...props}
				image={data.photo.large.gatsbyImageData}
			/>,
		]}
	/>
);
export default LargeMe;
