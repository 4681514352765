import React from "react";
import styled from "styled-components";
import LargeIcon from "../MeIcon/large";
import Link from "./components/link";
import Title from "./components/title";
import Subtitle from "./components/subtitle";
import Text from "./components/text";

const Icon = styled(LargeIcon)`
	box-shadow: ${(props) =>
		props.hasBoxShadow ? "0px 1px 5px rgba(0, 0, 0, 0.4)" : ""};
	img {
		border-radius: 60pt;
	}
	text-align: center;
	margin-bottom: 1em;
`;

const LandingBio = styled.div`
	place-self: center;
	text-align: center;
`;

const TextSection = styled(Text)`
	@media (min-width: 1120px) {
		margin-left: 2em;
		margin-right: 2em;
	}
	text-align: center;
	& > * {
		text-align: center;
	}
`;

const Header = ({ name, ...props }) => (
	<LandingBio {...props} className="p-author h-card">
		<Link to="/" rel="me">
			<Icon hasBoxShadow={true} />
		</Link>
		<TextSection>
			<Title>
				<Link to="/" rel="me" className="p-name u-author">
					{name}
				</Link>
			</Title>
			<Subtitle>
				I make things that help millions of people&nbsp;
				<br />
				find their communities and follow their passions.
			</Subtitle>
		</TextSection>
	</LandingBio>
);
export default Header;
