import React from "react";
import { graphql } from "gatsby";
// import Landing from "../components/Landing";
import Landing from "../components/Landing/fullscreen";
import { get } from "lodash";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import styled from "styled-components";

const Background = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -10;

	background: rgb(255, 255, 255, 1);

	@media (prefers-color-scheme: dark) {
		background: #160f19;
	}
`;

const LandingPage = (props) => {
	const site = get(props, "data.site.siteMetadata");
	const siteTitle = site.title;
	const siteDescription = site.description;
	const siteURL = site.siteUrl;

	return (
		<Layout>
			<Background />
			<Landing name={siteTitle} bio={siteDescription} />
			<Helmet>
				<title>{siteTitle}</title>
				<meta name="description" content={siteDescription} />

				<meta itemprop="name" content={siteTitle} />
				<meta itemprop="description" content={siteDescription} />

				<meta name="twitter:site" content="@SteveStreza" />
				<meta name="twitter:title" content={siteTitle} />
				<meta name="twitter:description" content={siteDescription} />
				<meta name="twitter:creator" content="@SteveStreza" />

				<meta property="og:title" content={siteTitle} />
				<meta property="og:type" content="article" />
				<meta property="og:url" content={siteURL} />
				<meta property="og:description" content={siteDescription} />
				<meta property="og:site_name" content={siteTitle} />
			</Helmet>
		</Layout>
	);
};

export const query = graphql`
	query LandingPage {
		site {
			siteMetadata {
				title
				description
				author
				siteUrl
			}
		}
		photo: file(relativePath: { eq: "photo.jpg" }) {
			small: childImageSharp {
				gatsbyImageData(layout: FIXED, width: 60, height: 60)
			}
		}
	}
`;

export default LandingPage;
