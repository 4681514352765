import React from "react";
import styled from "styled-components";
import Landing from "./index.js";

const FullscreenContainer = styled.div`
	min-height: 100%;
	padding-top: 2em;
	padding-bottom: 2em;

	backdrop-filter: blur(60px) saturate(200%);

	@media (prefers-color-scheme: dark) {
		backdrop-filter: blur(60px) saturate(150%);
	}

	background: rgb(255, 255, 255, 1);

	@media (prefers-color-scheme: dark) {
		background: #160f19;
	}

	// @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
	// 	background: rgb(255, 255, 255, 0.65);
	// 	@media (prefers-color-scheme: dark) {
	// 		background: rgb(22, 15, 25, 0.65);
	// 	}
	// }
`;

const BackgroundMaterial = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`;

const LandingPage = styled(Landing)`
	background: rgb(255, 255, 255, 1);

	@media (prefers-color-scheme: dark) {
		background: #160f19;
	}

	@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
		background: rgb(255, 255, 255, 0.65);
		@media (prefers-color-scheme: dark) {
			background: rgb(22, 15, 25, 0.65);
		}
	}
`;

const FullscreenLandingContainer = (props) => (
	<FullscreenContainer>
		{/* <FloatingContents> */}
		<LandingPage {...props} />
		{/* </FloatingContents> */}
	</FullscreenContainer>
);

export default FullscreenLandingContainer;
export { BackgroundMaterial };
