import styled from "styled-components";

const Title = styled.h1`
	margin: 0;
	margin-bottom: 0.5em;

	@media (max-width: 1120px) {
		text-align: left;
	}
	border-bottom: 1pt solid rgba(22, 15, 20, 0.3);

	color: rgb(20, 15, 25);
	a {
		color: rgb(20, 15, 25);
	}

	@media (prefers-color-scheme: dark) {
		color: white;
		a {
			color: white;
		}
		border-bottom: 1pt solid rgba(255, 255, 255, 0.3);
	}

	padding-bottom: 0.5em;
`;

export default Title;
