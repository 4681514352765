import { Link as GatsbyLink } from "gatsby";
import styled from "styled-components";

const Link = styled(GatsbyLink)`
	color: rgb(20, 15, 25);
	@media (prefers-color-scheme: dark) {
		color: white;
	}
	text-decoration: underline;
`;

const StyledA = styled.a`
	color: rgb(20, 15, 25);
	@media (prefers-color-scheme: dark) {
		color: white;
	}
	text-decoration: underline;
`;

export default Link;
export { StyledA };
