import React from "react";
import styled from "styled-components";

import Header from "./header";
import Links from "./links";
import Navigation from "./navigation";
import Works from "./works";

const LandingRoot = styled.div`
	width: 100%;
	min-height: 100%;
	display: grid;
	padding-top: 1em;

	@media (prefers-color-scheme: dark) {
		text-shadow: 0px 1px 4px rgba(22, 15, 25, 0.7);
	}

	@media (min-width: 1120px) {
		grid-template-columns: repeat(3, min(250px, 1fr));
		grid-template-rows: min-content min-content;
		column-gap: 20px;
		row-gap: 1em;
		justify-content: center;
		justify-items: center;
		align-content: center;
		align-items: top;
	}

	@media (max-width: 1120px) {
		padding-left: 1em;
		padding-right: 1em;
		padding-top: 3em;
		padding-bottom: 3em;
		row-gap: 1em;
		justify-content: start;
		justify-items: top;
		align-content: start;
		align-items: top;
	}
`;

const HeaderSection = styled.div`
	width: 100%;
	margin-bottom: 1em;
	h1 {
		border-bottom: 0;
		padding-bottom: 0;
	}
	@media (min-width: 1120px) {
		grid-row: 1;
		grid-column: 1 / span 3;
	}
`;

const Section = styled.div`
	@media (min-width: 1120px) {
		grid-row: 2;
	}
`;

const Landing = (props) => (
	<LandingRoot>
		<HeaderSection>
			<Header {...props} />
		</HeaderSection>
		<Section>
			<Navigation {...props} />
		</Section>
		<Section>
			<Links {...props} />
		</Section>
		<Section>
			<Works {...props} />
		</Section>
	</LandingRoot>
);
export default Landing;
