import React from "react";
import styled from "styled-components";
import Title from "./components/title";
import Text from "./components/text";
import Link from "./components/link";

const List = styled.ul`
	list-item-style: bullet;
	text-align: left;
	margin-bottom: 0;

	color: rgb(20, 15, 25);
	@media (prefers-color-scheme: dark) {
		color: white;
	}
`;

const ListItem = styled.li``;

const Navigation = () => (
	<Text>
		<Title>On My Website</Title>
		<List>
			<ListItem>
				<Link to="/blog/">My Blog</Link>
			</ListItem>
			<List>
				<ListItem>
					<Link to="/category/tech">On Tech</Link>
				</ListItem>
				<ListItem>
					<Link to="/category/games">On Games</Link>
				</ListItem>
				<ListItem>
					<Link to="/category/personal">Life Updates</Link>
				</ListItem>
			</List>
			<ListItem>
				<Link to="/projects/">Projects</Link>
			</ListItem>
			<ListItem>
				<Link to="/links/">Interesting Links</Link>
			</ListItem>
			<ListItem>
				<Link to="/about/">About</Link>
			</ListItem>
		</List>
	</Text>
);
export default Navigation;
