import styled from "styled-components";

const Subtitle = styled.h3`
	margin-top: 0.5em;
	line-height: 1.5em;
	color: rgb(20, 15, 25);
	@media (prefers-color-scheme: dark) {
		color: white;
	}

	@media (max-width: 560px) {
		br {
			display: none;
		}
	}
`;

export default Subtitle;
