import styled from "styled-components";

const Text = styled.div`
	padding-left: 8px;

	color: rgb(20, 15, 25);
	@media (prefers-color-scheme: dark) {
		color: white;
	}

	text-align: center;
	p {
		margin: 0;
		padding: 0;
		font-size: 11pt;
		font-weight: normal;
	}

	.u-author {
		font-weight: bold;
		text-decoration: none;
	}
`;

export default Text;
