import React from "react";
import styled from "styled-components";
import Title from "./components/title";
import Text from "./components/text";

import Teams from "./images/teams.png";
import TumblrDark from "./images/tumblr_white.png";
import TumblrLight from "./images/tumblr_blue.png";
import Pocket from "./images/pocket.svg";
import Soundboard from "./images/soundboard.png";
import Envelove from "./images/envelove.jpg";

const Icon = styled.div`
	width: 3em;
	height: 3em;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 1em;
	border-radius: 10px;
`;

const TeamsIcon = styled(Icon)`
	background-image: url(${Teams});
`;

const TumblrIcon = styled(Icon)`
	background-image: url(${TumblrLight});

	@media (prefers-color-scheme: dark) {
		background-image: url(${TumblrDark});
	}
`;

const PocketIcon = styled(Icon)`
	background-image: url(${Pocket});
`;

const SoundboardIcon = styled(Icon)`
	background-image: url(${Soundboard});
`;

const EnveloveIcon = styled(Icon)`
	background-image: url(${Envelove});
`;

const WorkListItem = styled.li`
	display: grid;
	grid-template-columns: min-content 100%;
	text-align: left;

	color: rgb(20, 15, 25);
	@media (prefers-color-scheme: dark) {
		color: white;
	}
`;

const WorkName = styled.div`
	font-weight: bold;
`;

const Work = ({ icon, name, label }) => (
	<WorkListItem>
		{icon()}
		<div>
			<WorkName>{name}</WorkName>
			<div>{label}</div>
		</div>
	</WorkListItem>
);

const List = styled.ul`
	margin-left: 0;
`;

const Works = () => (
	<Text>
		<Title>Stuff I've Made</Title>
		<List>
			<Work
				icon={() => <TeamsIcon />}
				name="Microsoft Teams"
				label="Sr. iOS Engineer, 2020-Present"
			/>
			<Work
				icon={() => <TumblrIcon />}
				name="Tumblr"
				label="Sr. iOS Engineer, 2015-2020"
			/>
			<Work
				icon={() => <PocketIcon />}
				name="Pocket"
				label="Lead Platform Dev, 2011-2013"
			/>
			<Work
				icon={() => <SoundboardIcon />}
				name="Soundboard"
				label="Mac App Dev, 2008-2010"
			/>
			<Work
				icon={() => <EnveloveIcon />}
				name="Envelove"
				label="Comic Book Writer, 2015-2017"
			/>
		</List>
	</Text>
);
export default Works;
